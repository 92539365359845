import React from 'react';
import { useState } from 'react';
import EMRContext from "./EMRContext";
import SplitPane, { Divider, SplitPaneLeft, SplitPaneRight, SplitPaneTop, SplitPaneBottom } from "./SplitPane";
import EMRBar from './components/EMRBar';
import EMRTree from './components/EMRTree';
import EMRMemo from './components/EMRMemo';
import EMRPDF from './components/EMRPDF';
import Waiting from './animations/Waiting';
import StandBy from './animations/StandBy';
import "./EMR.css";

const EMR = () => {

  const [memoData, setMemoData] = useState(null);
  const [docData, setDocData] = useState(null);
  const [seqnum, setSeqnum] = useState("");
  const [PDFLink, setPDFLink] = useState("");
  const [IDUpdated, setIDUpdated] = useState(false);

  const updateID = (updated) => {
    setIDUpdated(updated);
    setPDFLink("");
  }

  const updateData = (memo, doc) => {
    setMemoData(memo);
    setDocData(doc);
  }

  const updateLink = (num, link) => {
    setSeqnum(num);
    setPDFLink(link);
  }

  return (
    <EMRContext.Provider value={""}>
      <EMRBar updateID={updateID} updateData={updateData} PDFLink={PDFLink} />

      <SplitPane className="split-pane-frame">
        <SplitPaneLeft className="split-pane-left">
        { !IDUpdated ? (<Waiting />) : (
          memoData === null && docData === null ? null : (
          <SplitPane>
            <SplitPaneTop className="split-pane-top"/>
            <EMRMemo memoData={memoData} />

            <Divider className="separator-row" />
            <SplitPaneBottom  className="split-pane-bottom">
            <EMRTree docData={docData} updateLink={updateLink} />
            </SplitPaneBottom>
          </SplitPane>)) }
        </SplitPaneLeft>

        <Divider className="separator-col"/>
        <SplitPaneRight className="split-pane-right">
         <SplitPane className="PDF-container">
            { !IDUpdated || PDFLink === "" ? (<StandBy />) :
              <EMRPDF key={seqnum} PDFLink={PDFLink} /> }
          </SplitPane>
        </SplitPaneRight>
      </SplitPane>
    </EMRContext.Provider>
  )
}

export default EMR;