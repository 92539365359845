import getJSON from './getJSON';

const handleQuery = (query) => {
  return new Promise((resolve, reject) => {
    if (!query) {
      alert('Please enter a valid MRN, DoB, or Name.');
      return resolve({ docData: "Invalid" });
    }

    parseQuery(query)
      .then(({ ID, processedQuery, patientData }) => {
        if (ID === "Invalid") return resolve({ docData: "Invalid" });
        if (ID === "SearchTable") {
          return resolve({ formattedQuery:processedQuery, docData: "SearchTable" });
        }

        const memoAPI = `${process.env.REACT_APP_MEMO_API}${ID}`;
        const docAPI = `${process.env.REACT_APP_DOC_API}${ID}`;

        Promise.all([getJSON(processedQuery, memoAPI), getJSON(processedQuery, docAPI)])
          .then(([memoData, docData]) => {
            if (!memoData && !docData) {
              alert(`No patient record of MRN "${query}" exists.`);
              return resolve({ docData: "Invalid" });
            } else {
              const mergedData = patientData.concat(memoData);
              return resolve({ formattedQuery:processedQuery, memoData: mergedData, docData: docData });
            }
          })
          .catch(error => {
            alert(`No patient record of MRN "${query}" exists.`);
            resolve({ docData: "Invalid" });
          });
      })
      .catch(error => {
        alert(`No patient record of "${query}" exists [1].`);
        resolve({ docData: "Invalid" });
      });
  });
}

const parseQuery = (query) => {
  const regexID = /^[0-9]+$/;
  const regexDigit = /^\d$/;
  const regexDoB = /^(0?[1-9]|1[012])[-|/|.](0?[1-9]|[12][0-9]|3[01])[-|/|.](19|20)\d\d$/;
  let formattedQuery = query.trim();

  if (regexID.test(formattedQuery)) {
    return new Promise((resolve, reject) => {
      fetch(`${process.env.REACT_APP_SEARCH_API}${formattedQuery}`, { method: 'GET' })
        .then(response => {
          if (response.ok) return response.json();
          else {
            alert(`Request failed with status ${response.status}`);
            resolve({ ID: "Invalid" });
          }})
        .then(patientData => {
          if (patientData.length > 0) {
            const ID = patientData[0].PatientId;
            resolve({ ID, processedQuery:formattedQuery, patientData: patientData });
          } else {
            alert(`No patient record of MRN "${query}" exists.`);
            resolve({ ID: "Invalid" }) }
        })
        .catch(error => {
          alert(`No patient record of "${query}" exists [2].`);
          resolve({ ID: "Invalid" });
        });
    });

  } else if (!regexDigit.test(formattedQuery[0])) return Promise.resolve({ ID: "SearchTable", processedQuery: query });

  if (!regexDoB.test(formattedQuery)) {
    alert('Please enter DoB in the mm-dd-yyyy format.');
    return Promise.resolve({ ID: "Invalid" });
  }

  const parts = formattedQuery.split(/[-/\\.]/);
  let month = parts[0];
  let day = parts[1];
  let year = parts[2];

  if (month.length === 2 && month[0] === '0') { month = month[1] }
  if (day.length === 2 && day[0] === '0') { day = day[1] }
  formattedQuery = `${month}-${day}-${year}`;
  return Promise.resolve({ ID: "SearchTable", processedQuery:formattedQuery });
};

export default handleQuery;