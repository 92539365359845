const getJSON = (MRN, API) => {
  return fetch(API, { mode: 'cors' })
  .then(response => {
    if (!response.ok) { return null }
    return response.json();
  })
  .then(data => {
    if (data && data.length === 0) { return null }
    
  return data;
  })
  .catch(error => { return null });
}

export default getJSON;