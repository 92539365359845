import { Outlet } from 'react-router-dom';

const Portal = () => {

  return (
    <div>
      <h2 align="center"> Welcome to</h2>
      <h2 align="center">the Metro Dermatology Web Portal</h2>
      <main>
        <Outlet />
    </main>
    </div>
  )
}
  
export default Portal;