import React from 'react';
import { useState } from 'react';
import { Document } from 'react-pdf';
import { Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import AutoSizer from 'react-virtualized-auto-sizer';
import StandBy from '../animations/StandBy';
import Loading from '../animations/Loading';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const EMRPDF = ({ PDFLink }) => {

  const [numPages, setNumPages] = useState(0);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  return (
    <AutoSizer defaultWidth={"100%"}>
      { ({ width }) => (
        <Document file={PDFLink} noData={<StandBy />} loading={<Loading />} onLoadSuccess={onDocumentLoadSuccess}>
          { Array.from(new Array(numPages), (_, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={width} renderTextLayer={false} />)) }
          </Document>) }
      </AutoSizer>
  );
}

export default EMRPDF;