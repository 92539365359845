import React from 'react';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import handleQuery from './handleQuery';
import EMRTable from './EMRTable';
import Logo from './Logo.png';

const EMRBar = (props) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userID, setUserID] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [query, setQuery] = useState('');
  const [searchTable, setSearchTable] = useState(false);


  const IDUpdated = useRef(false);
  const memoData = useRef(null);
  const docData = useRef(null);
  const PDFLink = useRef('');
  const logoClicked = useRef(false);
  const logoutTimer = useRef(null);

  const navigate = useNavigate();
  let shouldRenderTable = loggedIn && submitted && searchTable;

  const handleLogin = (response) => {
    if (response) {
      const allowedDomain = process.env.REACT_APP_DOMAIN;
      const userObject = jwt_decode(response.credential);

      if (userObject) {
        const userEmail = userObject.email;
        if (userEmail && userEmail.endsWith(`@${allowedDomain}`)) {
          localStorage.setItem('loggedIn', 'true');
          setLoggedIn(true);
          setUserID(userEmail.split('@')[0]);
        } else {
          alert(`Only email addresses from ${allowedDomain} domain are allowed`);
        }
      }
    }

    resetLogoutTimer();
  };

  const handleLogout = useCallback(() => {
    localStorage.removeItem('loggedIn');
    setLoggedIn(false);
    clearTimeout(logoutTimer.current);
    window.location.reload(true);
  }, [logoutTimer]);
  
  const startLogoutTimer = useCallback(() => {
    logoutTimer.current = setTimeout(() => {
      handleLogout();
    }, 30 * 60 * 1000);     // 30 * 60 * 1000 = 30 minutes in milliseconds
  }, [logoutTimer, handleLogout]);

  const resetLogoutTimer = useCallback(() => {
    clearTimeout(logoutTimer.current);
    startLogoutTimer();
  }, [logoutTimer, startLogoutTimer]);

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setSubmitted(false);
    setQuery(inputValue);
    props.updateID(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    setSearchTable(false);

    try {
      const { formattedQuery, memoData, docData } = await handleQuery(query);

      switch (docData) {
        case "Invalid": return;

        case "SearchTable":
          setQuery(formattedQuery);
          IDUpdated.current = false;
          setSearchTable(true);
          return;

        default:
        setData(memoData, docData);
        setID(formattedQuery);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const setData = (memo, doc) => {
    memoData.current = memo;
    docData.current = doc;
    setSubmitted(true);
    props.updateData(memo, doc);
    props.updateID(true);
  };

  const setID = async (num) => {
    await new Promise((resolve) => {
      PDFLink.current = '';
      IDUpdated.current = true;
      setSearchTable(false);
      resolve();
    });
  };

  const handleFileClick = () => {
    const win = window.open(props.PDFLink, '_blank');
    win.onload = () => {
      win.print();
      win.close();
    };

    PDFLink.current = '';
  };

  const handleTable = (num) => {
    setSubmitted(true);

    handleQuery(num)
      .then(({ memoData, docData }) => {
        if (docData === "Invalid") return;

        setData(memoData, docData);
        setID(num);
      })
      .catch(error => { return });
  };

  const handleLogoClick = () => {
    const goPortal = () => {
      navigate('/portal');
    };

    if (!loggedIn) return;
    logoClicked.current = !logoClicked.current;
    goPortal();
  };

  useEffect(() => {
    startLogoutTimer();
    window.addEventListener('mousemove', resetLogoutTimer);
    window.addEventListener('keydown', resetLogoutTimer);

    return () => {
      window.removeEventListener('mousemove', resetLogoutTimer);
      window.removeEventListener('keydown', resetLogoutTimer);
      clearTimeout(logoutTimer);
    };
  }, [userID, logoutTimer, resetLogoutTimer, startLogoutTimer]);


  return (
    <div className="EMRBar">
      <Box sx={{flexGrow: 1}}>
        <AppBar position="static">
          <Toolbar>
            <div onClick={handleLogoClick} style={{cursor: 'pointer'}}>
              <Box component="img" sx={{height: 50, mr: 2, background: "white"}} alt="Web EMR LOGO" src={Logo} />
            </div>

            <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1, display: {xs: 'none', sm: 'block'}}}>
              Web EMR V1.1.7</Typography>

            { !loggedIn && (
              <GoogleLogin scope={process.env.REACT_APP_SCOPE} buttonText="Login with Google" auto_select="true"
                cookiePolicy={'single_host_origin'} onSuccess={handleLogin} onFailure={(err) => console.log(err)} />) }

            { loggedIn && (
              <div>
                <button style={{backgroundColor: 'black', color: 'white', borderRadius: '5px', marginRight: '50px'}}
                  onClick={handleLogout}>Logout</button>
                <button style={{backgroundColor: 'navy', color: 'white', borderRadius: '5px'}}
                  onClick={handleFileClick}>Print/Download PDF</button>
              </div>) }

            <Box sx={{flexGrow: 1}}></Box>

            { loggedIn && (
              <form onSubmit={handleSubmit}>
                <input onChange={handleChange} value={query}
                  placeholder="MRN, DoB(mm-dd-yyyy), or Name" type="text" style={{width: "28ch"}} />
                <input type="submit" value="Submit" />
              </form>) }
          </Toolbar>
        </AppBar>
      </Box>
      { shouldRenderTable ? <EMRTable query={query} handleTable={handleTable} /> : null }
    </div>
  );
};

export default EMRBar;