import React from 'react';

const EMRMemo = ({ memoData }) => {
  return (
    <div className="memo-container">
      { memoData === null ? null : (
        <ul style={{ marginTop: '1', paddingTop: '0', paddingLeft: '0', lineHeight: '1' }}>
          { memoData.map((data, index) => (
            <li key={index}>
              { Object.entries(data).map(([key, value]) => (
                <div key={key}>
                  <span style={{fontWeight: 'bold', fontSize: '20px'}}> {key}:
                  </span>
                  <span style={{fontSize: '20px', marginLeft: '5px'}}>{value}</span>
                </div>)) }
            </li>)) }
        </ul>) }
    </div>
  );
}

export default EMRMemo;