import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const imageApi = process.env.REACT_APP_IMAGE_API;
          
const EMRTree = (props) => {

  const [seqnum, setSeqnum] = useState("");
  const [PDFLink, setPDFLink] = useState("");

  const handleSelect = async (event, str) => {
    try {
      const node = JSON.parse(str);
      if (node.DocSource === "WEBLINK") {
        setPDFLink("");
        window.open(node.WebLink, "_blank");
        return;
      }

      let newSeqnum = node.Seqnum;
      const regex = /^[0-9]+$/;
  
      if (regex.test(newSeqnum)) {
        await fetch(imageApi + newSeqnum, { method: "GET" })
        .then((resp) => resp.text())
        .then((data) => {
          setSeqnum(newSeqnum);
          setPDFLink(data);
        })
      }
    }
    catch (error) {return}
  }

  useEffect(() => {
    props.updateLink(seqnum, PDFLink);
  }, [seqnum, PDFLink, props]);

  const renderTreeItems = (docData) => {
    return docData.map(item1 => (
      <TreeItem key={item1.DocumentCategory} nodeId={item1.DocumentCategory} label={item1.DocumentCategory}>
      { item1.Documets && Array.isArray(item1.Documets) ?
        item1.Documets.map(item2 => (
          <TreeItem key={item2.Seqnum} nodeId={JSON.stringify(item2)} value={item2.Seqnum}
            label={[item2.DocType, ' - ',
              `${item2.DocumentDate.split('T')[0].split('-')[1]}/
               ${item2.DocumentDate.split('T')[0].split('-')[2]}/
               ${item2.DocumentDate.split('T')[0].split('-')[0]}`]} />
          )) : null }
      </TreeItem>))
  }

  return (
    <div>
      { !Array.isArray(props.docData) || props.docData.length === 0 ? <>No Documents</> :
          (<TreeView aria-label="file system navigator" onNodeSelect={handleSelect}
              defaultCollapseIcon={<ExpandMoreIcon />} defaultExpandIcon={<ChevronRightIcon />}
              sx={{height: 1080, flexGrow: 1, maxWidth: 625, overflowX: 'hidden', overflowY: 'auto'}}>
 
            { renderTreeItems(props.docData) }
          </TreeView>) }
    </div>
  )
}

export default EMRTree;