import React from "react";
import { RingLoader } from "react-spinners";

const override = { display: "flex", position: "relative", top: "20px", left: "25px" };

const StandBy = ({ loading }) => {
  return (
    <div>
        <RingLoader color="#000080" loading={loading} cssOverride={override} size={150} />
    </div>
  );
};

export default StandBy;