import React from "react";
import { createRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import SplitPaneContext from "./SplitPaneContext";

const SplitPane = ({ children, ...props }) => {
  const [clientHeight, setClientHeight] = useState(null);
  const [clientWidth, setClientWidth] = useState(null);
  const xDividerPos = useRef(null);
  const containerRef = useRef(null);

  const onMouseHoldDown = (event) => {
    xDividerPos.current = event.clientX;
  }

  const onMouseHoldUp = () => {
    xDividerPos.current = null;
  }

  const onMouseHoldMove = (event) => {
    if (!xDividerPos.current) { return }

    const deltaX = event.clientX - xDividerPos.current;
    const newWidth = clientWidth + deltaX;

    if (newWidth >= 0) {
      setClientWidth(newWidth);
    }
  
    xDividerPos.current = event.clientX;
  }

  const onTouchStart = (event) => {
    xDividerPos.current = event.touches[0].clientX;
  }

  const onTouchEnd = () => {
    xDividerPos.current = null;
  }

  const onTouchMove = (event) => {
    if (!xDividerPos.current) { return }

    const deltaX = event.touches[0].clientX - xDividerPos.current;
    const newWidth = clientWidth + deltaX;

    if (newWidth >= 0) {
      setClientWidth(newWidth);
    }

    xDividerPos.current = event.touches[0].clientX;
  }

  useEffect(() => {
    const containerElement = containerRef.current;

    containerElement.addEventListener("mousedown", onMouseHoldDown);
    containerElement.addEventListener("mouseup", onMouseHoldUp);
    containerElement.addEventListener("mousemove", onMouseHoldMove);
    containerElement.addEventListener("touchstart", onTouchStart);
    containerElement.addEventListener("touchend", onTouchEnd);
    containerElement.addEventListener("touchmove", onTouchMove);

    return () => {
      containerElement.removeEventListener("mousedown", onMouseHoldDown);
      containerElement.removeEventListener("mouseup", onMouseHoldUp);
      containerElement.removeEventListener("mousemove", onMouseHoldMove);
      containerElement.removeEventListener("touchstart", onTouchStart);
      containerElement.removeEventListener("touchend", onTouchEnd);
      containerElement.removeEventListener("touchmove", onTouchMove);
    };
  }, [clientWidth /* onMouseHoldMove, onTouchMove */]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div {...props} ref={containerRef} onTouchStart={onTouchStart}>
      <SplitPaneContext.Provider
        value={{clientHeight, setClientHeight, clientWidth, setClientWidth, onMouseHoldDown}}>
        {children}
      </SplitPaneContext.Provider>
    </div>
  )
};

export const Divider = (props) => {
  const { onMouseHoldDown, onTouchStart } = useContext(SplitPaneContext);
  return <div {...props} onMouseDown={onMouseHoldDown} onTouchStart={onTouchStart} />;
}

export const SplitPaneLeft = (props) => {
  const topRef = createRef();
  const { clientWidth, setClientWidth } = useContext(SplitPaneContext);

  useEffect(() => {
    if (!clientWidth) {
      setClientWidth(topRef.current.clientWidth / 2); return;
    }

    topRef.current.style.minWidth = clientWidth + "px";
    topRef.current.style.maxWidth = clientWidth + "px";
  }, [clientWidth, setClientWidth, topRef])

  return (<div {...props} ref={topRef} style={{ overflowY: "hidden" }}>
            {props.children}
          </div>)
};

export const SplitPaneRight = (props) => {
  return <div {...props} />;
};

export const SplitPaneTop = (props) => {
  const topRef = createRef();
  const { clientHeight, setClientHeight } = useContext(SplitPaneContext);

  useEffect(() => {
    if (!clientHeight) {
      setClientHeight(topRef.current.clientHeight);
      return;
    }

    topRef.current.style.minHeight = clientHeight + "px";
    topRef.current.style.maxHeight = clientHeight + "px";
  }, [clientHeight, setClientHeight, topRef]);

  return <div {...props} ref={topRef} />
};

export const SplitPaneBottom = (props) => {
  return <div {...props} />
};

export default SplitPane;