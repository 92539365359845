import React from "react";
import { PacmanLoader } from "react-spinners";

const override = { display: "flex", position: "relative", top: "20px", left: "25px" };

const Waiting = ({ loading }) => {
  return (
    <div>
        <PacmanLoader color="#000080" loading={loading} cssOverride={override} size={100} />
        <h2>Waiting for Input</h2>
    </div>
  );
};

export default Waiting;