import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import EMR from './WebEMR/EMR';
import Portal from './WebPortal/Portal';
import './App.css';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<EMR />} />
      <Route path="/portal" element={<Portal />} />
    </Routes>
  )
}

export default App;