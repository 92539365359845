import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';

const columns = [
  { field: 'id', headerName: 'Patient ID', width: 110, headerClassName: 'super-app-theme--header'},
  { field: 'MRN', headerName: 'MRN', width: 100, headerClassName: 'super-app-theme--header'},
  { field: 'FullName', headerName: 'Full Name', width: 300, headerClassName: 'super-app-theme--header' },
  { field: 'DOB', headerName: 'Date of Birth', width: 150, align: 'right', headerClassName: 'super-app-theme--header' },
  { field: 'Gender', headerName: 'Gender', width: 100, align: 'center', headerClassName: 'super-app-theme--header'}
];

const EMRTable = ({ query, handleTable }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData(query);
  }, [query]);


  const fetchData = (query) => {
    fetch(`${process.env.REACT_APP_SEARCH_API}${query}`, { method: 'GET' })
    .then((response) => {
      if (!response.ok) {
        alert(`No patient record of "${query}" exists. [3]`);
        return;
      }
      return response.json();
    })
    .then((jsonData) => {
      if (jsonData.length === 0) {
        alert(`No patient record of "${query}" exists [4].`);
        return;
      }

      const rows = jsonData.map((row) => ({
        id: row.PatientId ? row.PatientId.toString().trim() : '',
        MRN: row.MRN ? row.MRN.trim() : '',
        FullName: row.FullName ? row.FullName.trim() : '',
        DOB: row.DOB ? row.DOB.trim() : '',
        Gender: row.Gender ? row.Gender.trim() : '',
      }));
      
      setOpen(true);
      setData(rows);
    })
    .catch((error) => {
      alert(`Error occurred while fetching data: ${error}`);
    });  
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowClick = (params, event) => {
    event.preventDefault();
    handleTable(params.row.MRN);
    setOpen(false);
  };

  if (open) {
    return (
      <Dialog open={open} maxWidth={'md'} sx={{'& .super-app-theme--header': {backgroundColor: 'rgba(230,230,230)'}}}>
        <DataGrid onRowClick={handleRowClick} rows={data} rowCount={data.length}
          columns={columns} pagination pageSize={10} pageSizeOptions={[10, 25, 50, 100]} />
        <Button onClick={handleClose}> Close </Button>
      </Dialog>
    ) }
  else return null;
}

export default EMRTable;