import React from "react";
import { FadeLoader } from "react-spinners";

const override = { display: "flex", position: "relative", top: "20px", left: "25px" };

const Loading = ({ loading }) => {
  return (
    <div>
        <FadeLoader color="#000080" loading={loading} cssOverride={override} />
        <h3>Loading</h3>
    </div>
  );
};

export default Loading;